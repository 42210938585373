import React from 'react';
import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { EmbedWallet, WalletEnvironment } from '@cere/embed-wallet';
import { WALLET_APP_ID, WALLET_ENV } from '../../../constants';

export const WalletContext = createContext<EmbedWallet | null>(null);

export const WalletProvider = ({ children }: PropsWithChildren<{}>) => {
  const wallet = useMemo(() => new EmbedWallet(), []);

  useEffect(() => {
    if (wallet.status !== 'not-ready') {
      return;
    }

    wallet.init({
      appId: WALLET_APP_ID,
      env: WALLET_ENV as WalletEnvironment,
      popupMode: 'modal',
      connectOptions: {
        mode: 'modal',
      },
    });
  }, [wallet]);

  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>;
};
