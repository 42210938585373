import { useCallback, useContext, useMemo } from 'react';
import { v1 as uuid } from 'uuid';

import { ModalComponentType, ModalProviderContext } from '../../components';

export function useModal<
  TReturnData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TComponentType extends ModalComponentType<TReturnData, any>,
  TData extends Omit<Parameters<TComponentType>['0'], 'onComplete' | 'onCancel' | 'id'>,
>(
  Component: TComponentType,
  onComplete: Parameters<TComponentType>['0']['onComplete'] = () => undefined,
  onCancel: Parameters<TComponentType>['0']['onCancel'] = () => undefined,
) {
  const id = useMemo(() => uuid(), []);
  const { onOpen, onClose } = useContext(ModalProviderContext);

  const onOpenModal = useCallback(
    (data?: TData) => {
      onOpen(id, Component, onComplete, onCancel, data);
    },
    [Component, id, onCancel, onComplete, onOpen],
  );

  const onCloseModal = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return [onOpenModal, onCloseModal];
}
