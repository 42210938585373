import React from 'react';
import Reward1 from '../../../assets/png/Reward.png';
import Reward2 from '../../../assets/png/Reward-1.png';
import Reward3 from '../../../assets/png/Reward-2.png';
import { ReactComponent as FiltersIcon } from '../../../assets/svg/icons/filters-icon.svg';
import { ReactComponent as SortIcon } from '../../../assets/svg/icons/sort-icon.svg';
import styles from './achievents-list.module.scss';
import { useIsMobileDevice, useIsTabletDevice } from '../../../hooks/device';
import { Flex, Grid, NftForSale, Text } from '../../../components';
import { useTour } from '@reactour/tour';

export function AchievementsList() {
  const { isOpen } = useTour();
  const isMobile = useIsMobileDevice();
  const isTablet = useIsTabletDevice();
  const rewardImages = isMobile
    ? [`${Reward1}`, `${Reward2}`]
    : [`${Reward1}`, `${Reward2}`, `${Reward3}`];
  return (
    <div className='container relative mx-auto lg:max-w-[1158px]'>
      <Text
        className={styles.comingSoon}
        font='Yapari Variable Trial'
        size={isMobile ? '60-64' : '92-110'}
        color='gray'
      >
        COMING SOON
      </Text>
      <div className={isOpen ? 'opacity-1' : 'opacity-[.47]'}>
        <div className={styles.marketPlace}>
          <Text
            className='fourth-step'
            font='Yapari Variable Trial'
            size={isMobile ? '24-28' : '47-58'}
            color='gray'
          >
            Marketplace
          </Text>
          <Flex justify='space-between' offset={{ top: 29 }}>
            {!isMobile && !isTablet && (
              <Text size='16-20' color='gray'>
                Sell your trophies / NFTs
              </Text>
            )}
            <Flex colGap={27}>
              <Flex alignItems='center' colGap={6}>
                <FiltersIcon />
                <Text size='16-20' color='gray'>
                  Filters
                </Text>
              </Flex>
              <Flex alignItems='center' colGap={6}>
                <SortIcon />
                <Text size='16-20' color='gray'>
                  <b>Sorted:</b> Recently added
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Grid
            columns={isMobile ? '1fr' : 'repeat(3, auto)'}
            colGap={20}
            rowGap={20}
            offset={{ top: isMobile ? 14 : 36 }}
          >
            {rewardImages.map((image) => (
              <NftForSale
                key={image}
                nftImage={image}
                nftInfo={{
                  title: 'Game Title',
                  description: 'Weekly Tournament 21 1st place',
                  stock: '6 of 100 in stock',
                  price: 35,
                }}
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
