import classNames from 'classnames';

import { buildOffsetStyles } from '../../../utils';
import { FlexPropsType } from './Flex.types';
import styles from './Flex.module.scss';

export default function Flex({
  as: Component = 'div',
  align = 'stretch',
  alignItems = 'stretch',
  direction = 'row',
  inline = false,
  justify = 'flex-start',
  wrap = 'nowrap',
  fluid = false,
  rowGap,
  colGap,
  className,
  children,
  onClick,
  offset,
}: FlexPropsType) {
  return (
    <Component
      className={classNames(className, {
        [styles.alignContentCenter]: align === 'center',
        [styles.alignContentFlexEnd]: align === 'flex-end',
        [styles.alignContentFlexStart]: align === 'flex-start',
        [styles.alignContentSpaceAround]: align === 'space-around',
        [styles.alignContentSpaceBetween]: align === 'space-between',
        [styles.alignContentStretch]: align === 'stretch',

        [styles.alignItemsCenter]: alignItems === 'center',
        [styles.alignItemsBaseline]: alignItems === 'baseline',
        [styles.alignItemsFlexEnd]: alignItems === 'flex-end',
        [styles.alignItemsFlexStart]: alignItems === 'flex-start',
        [styles.alignItemsStretch]: alignItems === 'stretch',

        [styles.displayFlex]: !inline,
        [styles.displayInlineFlex]: inline,

        [styles.flexDirectionRow]: direction === 'row',
        [styles.flexDirectionRowReverse]: direction === 'row-reverse',
        [styles.flexDirectionColumn]: direction === 'column',
        [styles.flexDirectionColumnReverse]: direction === 'column-reverse',

        [styles.flexWrapWrap]: wrap === 'wrap',
        [styles.flexWrapNowrap]: wrap === 'nowrap',
        [styles.flexWrapWrapReverse]: wrap === 'wrap-reverse',

        [styles.justifyContentCenter]: justify === 'center',
        [styles.justifyContentStretch]: justify === 'stretch',
        [styles.justifyContentFlexEnd]: justify === 'flex-end',
        [styles.justifyContentFlexStart]: justify === 'flex-start',
        [styles.justifyContentSpaceAround]: justify === 'space-around',
        [styles.justifyContentSpaceBetween]: justify === 'space-between',
        [styles.justifyContentSpaceEvenly]: justify === 'space-evenly',

        [styles.fluid]: fluid,
      })}
      style={{
        ...buildOffsetStyles(offset),
        columnGap: colGap,
        rowGap,
      }}
      onClick={onClick}
    >
      {children}
    </Component>
  );
}
