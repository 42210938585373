import styles from './running-text.module.scss';
import { Flex, Text } from '../../index';
import Button from '../../../components/ui/Button/Button';

export function RunningText() {
  const text = (
    <Text className={styles.line} size='20-28' font='Yapari Variable Trial' color='contrast'>
      Are you a game dev? Our team can help you create a portal like this tailored to your needs.
      <Button link='https://cere.network/game-developer/'> Learn more here.</Button>
    </Text>
  );
  return (
    <Flex direction='column' className={styles.container}>
      <Flex>
        {text}
        {text}
      </Flex>
    </Flex>
  );
}
