import './App.scss';

import React from 'react';
import { TourProvider } from '@reactour/tour';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import {
  AchievementsList,
  Footer,
  Header,
  OwnPortal,
  PlayGames,
  SliderBlock,
  StepsBlock,
  Popover,
  Tournaments,
  WalletProvider,
  ModalProvider,
  RunningText,
} from './components';
import { getSteps } from './constants/steps';
import { useIsMobileDevice } from './hooks/device';

function App() {
  const disableBody = (target: Element | null) => disableBodyScroll(target as Element);
  const enableBody = (target: Element | null) => enableBodyScroll(target as Element);
  const isMobile = useIsMobileDevice();
  return (
    <WalletProvider>
      <ModalProvider>
        <TourProvider
          steps={getSteps(isMobile)}
          afterOpen={!isMobile ? disableBody : undefined}
          beforeClose={!isMobile ? enableBody : undefined}
          ContentComponent={Popover}
          scrollSmooth
          maskClassName='maskElement'
          styles={{
            maskArea: (base) => ({ ...base, rx: 8 }),
            maskWrapper: (base) => ({
              ...base,
              color: 'rgba(22, 29, 48, 0.7)',
              rx: 8,
            }),
            maskRect: (base) => ({ ...base, rx: 8 }),
            popover: (base) => ({
              ...base,
              width: isMobile ? '349px' : '543px',
              maxWidth: isMobile ? '349px' : '543px',
              border: '1px solid rgba(245, 250, 252, 0.2)',
              backgroundColor: /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                ? 'rgba(33, 42, 67, 0.93)'
                : 'rgba(245, 250, 252, 0.07)',
              backdropFilter: 'blur(24px)',
              borderRadius: '16px',
              padding: '48px',
              boxShadow: 'none',
              rx: 8,
            }),
          }}
          showNavigation={false}
          showPrevNextButtons={false}
          showBadge={false}
          showDots={false}
          showCloseButton={false}
        >
          <main className='main'>
            <div className='video-container'>
              <div className='videoBg' />
              <video autoPlay muted loop playsInline>
                <source
                  src='/static/video-output-12B0FBCA-B7D6-4B16-94B4-0B51D42C03D5.mp4'
                  type='video/mp4'
                />
              </video>
              <Header />
              <SliderBlock />
            </div>
            <RunningText />
            <PlayGames />
            <AchievementsList />
            <StepsBlock />
            <Tournaments />
            <OwnPortal />
            <Footer />
          </main>
        </TourProvider>
      </ModalProvider>
    </WalletProvider>
  );
}

export default App;
