import { useMedia } from 'react-use';

import variables from '../../styles/common/_export.module.scss';

export default function useIsTabletDevice(
  breakpointTablet = parseInt(variables.breakpointTablet, 10),
  breakpointDesktop = parseInt(variables.breakpointDesktop, 10),
) {
  return useMedia(`(min-width: ${breakpointTablet}px) and (max-width: ${breakpointDesktop - 1}px)`);
}
