import { useMemo } from 'react';
import classNames from 'classnames';

import { buildOffsetStyles } from '../../../../../utils';
import { TitlePropsType } from './Title.types';
import styles from './Title.module.scss';

export default function Title({
  children,
  as: Component = 'h1',
  color = 'default',
  offset,
  align: textAlign,
  className,
  onClick,
}: TitlePropsType) {
  const componentStyles = useMemo(
    () => ({
      ...buildOffsetStyles(offset),
      textAlign,
    }),
    [offset, textAlign],
  );

  return (
    <Component
      onClick={onClick}
      style={componentStyles}
      className={classNames(styles.title, styles[`title-${Component}`], className, {
        [styles.default]: color === 'default',
        [styles.contrast]: color === 'contrast',
        [styles.contrast]: color === 'contrast',
        [styles.gray040]: color === 'gray040',
        [styles.accent]: color === 'accent',
      })}
    >
      {children}
    </Component>
  );
}
