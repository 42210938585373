import { createContext } from 'react';

import { ModalProviderContextType } from './ModalProvider.types';

// eslint-disable-next-line
export const ModalProviderContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ModalProviderContextType<any, any>
>({
  onOpen: () => {
    throw new Error("ModalProvider context hasn't been defined");
  },
  onClose: () => {
    throw new Error("ModalProvider context hasn't been defined");
  },
  getIsOpen: () => {
    throw new Error("ModalProvider context hasn't been defined");
  },
  onRemove: () => {
    throw new Error("ModalProvider context hasn't been defined");
  },
  onClearAll: () => {
    throw new Error("ModalProvider context hasn't been defined");
  },
});
