import { useContext } from 'react';

import { WalletContext } from '../components';

export const useWallet = () => {
  const wallet = useContext(WalletContext);

  if (!wallet) {
    throw new Error('Not in wallet context');
  }

  return wallet;
};
