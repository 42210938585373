import { Button, Flex, Grid, Modal, Text } from '../../../components';
import { BookDemoModalPropsType } from './book-demo-modal.types';
import styles from './book-demo-modal.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsMobileDevice } from '../../../hooks/device';
import { Truncate, useWallet, useWalletAddress, useWalletStatus } from '../../../hooks';
import * as process from 'process';

export function BookDemoModal({ id, onCancel }: BookDemoModalPropsType) {
  const wallet = useWallet();
  const walletAddress = useWalletAddress();
  const walletStatus = useWalletStatus();
  const isWalletConnectDisabled = walletStatus !== 'ready';
  const isMobile = useIsMobileDevice();
  const [successBooking, setBooking] = useState<boolean>(false);
  const [formValues, setValue] = useState({
    name: '',
    email: '',
    message: '',
    privacy: false,
  });
  const isValidEmail = useMemo(() => {
    return !!formValues.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    );
  }, [formValues.email]);

  const [disableSubmit, setDisable] = useState(true);

  useEffect(() => {
    if (
      formValues.name !== '' &&
      formValues.email !== '' &&
      Boolean(formValues.privacy) &&
      isValidEmail
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formValues]);

  const handleOnSubmit = useCallback(async () => {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/email-sender/send`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: formValues.name,
        email: formValues.email,
        message: formValues.message,
      }),
    });

    if (response.status === 200) {
      setBooking(true);
    }
  }, [formValues]);

  const handleOnChange = useCallback(
    (e: React.SyntheticEvent<HTMLFormElement> | undefined): void => {
      setValue((prevState) => ({
        ...prevState,
        [(e?.target as HTMLInputElement).name]:
          (e?.target as HTMLInputElement).type === 'checkbox'
            ? (e?.target as HTMLInputElement).checked
            : (e?.target as HTMLInputElement).value,
      }));
    },
    [],
  );

  console.log('formValues', formValues);
  return (
    <Modal id={id} onClose={onCancel} theme='confirm'>
      <Text
        font='Yapari Variable Trial'
        size='24-28'
        color='gray'
        offset={{ bottom: 15 }}
        align='center'
        className={styles.title}
      >
        {successBooking ? 'Thank you' : 'Book a Demo'}
      </Text>
      <Text
        size={!isMobile ? '16-24' : '14-20'}
        color='gray'
        align='center'
        className={styles.content}
        offset={{ bottom: successBooking ? 0 : 22 }}
      >
        {successBooking
          ? 'Thanks for contacting us! We will get in touch with you shortly.'
          : "If you're a game studio or developer looking to learn more about how Cere can decentralize your game, just fill out the form below and we'll be in touch."}
      </Text>
      {!successBooking && (
        <form className={styles.form} onChange={handleOnChange}>
          <Grid
            columns={!isMobile ? '1fr 1fr' : '1fr'}
            rowGap={!isMobile ? 0 : 8}
            colGap={18}
            offset={{ bottom: 4 }}
          >
            <div>
              <label className={styles.label} htmlFor='text-input'>
                Name*
              </label>
              <input
                name='name'
                required
                className={styles.input}
                id='text-input'
                type='text'
                placeholder='John Doe'
              />
            </div>
            <div>
              <label className={styles.label} htmlFor='text-input-email'>
                Email*
              </label>
              <input
                name='email'
                required
                className={styles.input}
                id='text-input-email'
                type='email'
                placeholder='youremail@mail.com'
              />
            </div>
          </Grid>
          <label className={styles.label} htmlFor='textarea'>
            Message
          </label>
          <textarea
            name='message'
            className={styles.input}
            id='textarea'
            placeholder='Describe your case...'
          />
          <label className={styles['form-control']}>
            <input type='checkbox' name='privacy' />
            <Flex className={styles.privacy}>
              I agree to the
              <Button
                className={styles.link}
                skin='link'
                link='https://cere.network/privacy-statement'
              >
                Privacy Policy
              </Button>
              *
            </Flex>
          </label>
        </form>
      )}
      {successBooking ? (
        <Grid
          columns={isMobile ? '1fr' : '1fr 1fr'}
          offset={{ top: isMobile || successBooking ? 24 : 0 }}
          rowGap={isMobile ? 18 : 0}
          colGap={18}
        >
          <Button
            className={styles.button}
            onClick={() => {
              onCancel();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            Game portal
          </Button>
          <Button
            skin='outlined'
            className={styles.button}
            disabled={isWalletConnectDisabled}
            onClick={async () => {
              onCancel();
              await wallet.connect();
            }}
          >
            {walletAddress ? (
              <Truncate variant='hex' maxLength={10} text={walletAddress} />
            ) : (
              'Create wallet'
            )}
          </Button>
        </Grid>
      ) : (
        <Flex alignItems='center'>
          <Button className={styles.submit} disabled={disableSubmit} onClick={handleOnSubmit}>
            Submit
          </Button>
        </Flex>
      )}
    </Modal>
  );
}
