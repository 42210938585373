import { useEffect, useState } from 'react';
import { useWallet } from './use-wallet';

export const useWalletStatus = () => {
  const wallet = useWallet();
  const [status, setStatus] = useState(wallet.status);

  useEffect(() => {
    const unsubscribe = wallet.subscribe('status-update', setStatus);

    return () => unsubscribe();
  }, [wallet]);

  return status;
};
