import { PopoverPropsType } from './popover.types';
import { BookDemoModal, Button, Flex, Grid, Text } from '../../../../../components';
import React, { ReactNode, useMemo } from 'react';
import styles from './popover.module.scss';
import { useTour } from '@reactour/tour';
import { useModal, useIsMobileDevice } from '../../../../../hooks';
import classNames from 'classnames';

export function Popover({ currentStep, setCurrentStep }: PopoverPropsType) {
  const isMobile = useIsMobileDevice();
  const { setIsOpen } = useTour();
  const [openModal] = useModal(BookDemoModal);
  const stepsInfo: {
    title: string;
    content: string;
    buttons: ReactNode[];
    learnMoreLink: string;
  }[] = [
    {
      title: 'Welcome',
      content:
        'Welcome to Cere’s incredible decentralized gaming portal! In this quick guide, you’ll get a glimpse of the possibilities our tool kit can offer.',
      buttons: [
        <Button
          key='1'
          className={classNames(styles.customButton, styles.fluid)}
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        >
          Let’s start!
        </Button>,
      ],
      learnMoreLink: 'https://cere.network/game-developer/',
    },
    {
      title: 'Gaming Wallet',
      content:
        "The Cere wallet uses a JS-SDK library for easy onboarding with email/social login, via web3auth. It's a whitelabel wallet, easily connectable to your portal.",
      buttons: [],
      learnMoreLink: 'https://cere.notion.site/Wallet-6a91efc78c164e3f82d74c1f50cbaa33',
    },
    {
      title: 'Multiple Games',
      content:
        'Unlock a world where game assets and achievements are ported over to other games, unleashing a new era in gaming.',
      buttons: [],
      learnMoreLink: 'https://cere.network/tools-services/#sdk',
    },
    {
      title: 'Build your own NFT Marketplace',
      content:
        'Create your very own, self-branded NFT marketplace for buying and selling in-game assets and achievements.',
      buttons: [],
      learnMoreLink: 'https://cere.network/tools-services/#marketplace',
    },
    {
      title: 'Decentralized Leaderboard',
      content:
        'Players of your games can save their scores to a Decentralized Leaderboard, allowing them to compete in thrilling time-based tournaments!',
      buttons: [
        <Button
          key='1'
          className={styles.customButton}
          onClick={() => {
            setIsOpen(false);
            openModal();
          }}
        >
          Try it now!
        </Button>,
        <Button
          key='2'
          skin='outlined'
          className={styles.customButton}
          onClick={() => {
            setIsOpen(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          Main Page
        </Button>,
      ],
      learnMoreLink: 'https://cere.network/game-developer/',
    },
  ];

  const columns = useMemo(() => {
    if (stepsInfo[currentStep].buttons.length === 1) {
      return '1fr';
    }
    if (stepsInfo[currentStep].buttons.length !== 1 && !isMobile) {
      return '196px 196px';
    }
    return '122px 122px';
  }, [stepsInfo, currentStep, isMobile]);

  return (
    <div className={styles.container}>
      <Text
        font='Yapari Variable Trial'
        size='24-29'
        color='gray'
        offset={{ bottom: 15 }}
        align='center'
        className={styles.title}
      >
        {currentStep === 0 ? 'Welcome' : stepsInfo[currentStep].title}
      </Text>
      <Text size='16-24' color='gray' align='center' className={styles.content}>
        {stepsInfo[currentStep].content}
      </Text>
      <Flex direction='column'>
        {stepsInfo[currentStep].buttons.length > 0 ? (
          <Flex direction='column'>
            <Grid
              columns={columns}
              className={styles.buttonGroupSmall}
              colGap={14}
              rowGap={isMobile ? 18 : 0}
              offset={{ top: 36 }}
            >
              {stepsInfo[currentStep].buttons.map((button: ReactNode) => button)}
            </Grid>
          </Flex>
        ) : (
          <Grid
            columns={isMobile ? '122px 122px' : '196px 196px'}
            colGap={14}
            rowGap={isMobile ? 18 : 0}
            offset={{ top: 24 }}
            className={styles.buttonGroupSmall}
          >
            <Button
              className={styles.customButton}
              onClick={() => {
                if (stepsInfo[currentStep + 1].title === 'Multiple Games') {
                  document.querySelector('.third-step')?.scrollIntoView();
                }

                setTimeout(() => setCurrentStep(currentStep + 1), 0);
              }}
            >
              Next
            </Button>
            <Button
              className={styles.customButton}
              onClick={() => setCurrentStep(currentStep - 1)}
              skin='outlined'
            >
              Back
            </Button>
          </Grid>
        )}
        <Button
          className={styles.learnMore}
          skin='link'
          link={stepsInfo[currentStep].learnMoreLink}
        >
          Learn more
        </Button>
      </Flex>
    </div>
  );
}
