import { LeaderboardPropsType } from '../../../components/ui/leaderboard/leaderboard.types';
import { Flex, Grid, Text } from '../../../components';
import classNames from 'classnames';
import MysteryBox from '../../../assets/png/mystery-box.png';
import { ReactComponent as YouIcon } from '../../../assets/svg/icons/you-icon.svg';
import styles from './leaderboard.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LeaderBoard } from '../../../types';
import { Truncate, useWalletAddress } from '../../../hooks';
import * as process from 'process';

export function Leaderboard({ gameId }: LeaderboardPropsType) {
  const [leaderBoard, setLeaderBoard] = useState<LeaderBoard[]>([]);
  const walletAddress = useWalletAddress();

  const getLeaderBoard = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/leader-board/tournament/game-id/${gameId}`,
    );

    if (response.ok) {
      const result: LeaderBoard[] = await response.json();
      const sorted = result.sort().map((item, idx) => ({ ...item, rank: idx + 1 }));
      setLeaderBoard(sorted);
    }
  }, []);

  useEffect(() => {
    void getLeaderBoard();
  }, []);

  const renderRow = (row?: LeaderBoard) => {
    if (!row) {
      return;
    }
    return (
      <>
        <Text size='14-20' color='gray' weight='bold' className={styles.rank}>
          <div className='rank'>{row.rank}</div>
        </Text>
        <Text size='14-20' color='gray' weight='medium'>
          <Grid columns='auto 20px' colGap={6} align='center'>
            <Truncate variant='hex' maxLength={10} text={row.walletId} />
            {row.walletId === walletAddress && <YouIcon />}
          </Grid>
        </Text>
        <Text size='14-20' color='gray' weight='bold'>
          <Grid
            columns={row.rank && row.rank <= 20 ? '36px auto' : '1fr'}
            colGap={16}
            align='center'
          >
            {row.rank && row.rank <= 20 && <img src={MysteryBox} alt='mystery-box' />}
            {row.score}
          </Grid>
        </Text>
      </>
    );
  };

  const activeRow = useMemo(() => {
    return leaderBoard.find((s) => s.walletId === walletAddress);
  }, [leaderBoard, walletAddress]);

  const leaderBoardValues = useMemo(() => {
    const idx = leaderBoard.findIndex((s) => s.walletId === walletAddress);
    return !walletAddress
      ? leaderBoard
      : [...leaderBoard.slice(0, idx), ...leaderBoard.slice(idx + 1, leaderBoard.length - 1)];
  }, [leaderBoard, walletAddress]);

  return (
    <div className={styles.leaderBoardContainer}>
      <Flex direction='column' className={styles.leaderboard}>
        <Text size='24-32' weight='semibold' color='gray'>
          Tournament Leaderboard
        </Text>
        <Flex
          className={classNames(styles.row, styles.tableHeader, styles.header)}
          offset={{ top: 24, bottom: 16 }}
        >
          <Text size='12-16' color='gray' weight='medium'>
            Rank
          </Text>
          <Text size='12-16' color='gray' weight='medium'>
            Player
          </Text>
          <Text size='12-16' color='gray' weight='medium'>
            Score
          </Text>
        </Flex>
        <Flex className={classNames(styles.row)} direction='column' rowGap={6}>
          {walletAddress && (
            <Flex
              className={classNames(styles.leaderboardRow, {
                [styles.active]: true,
              })}
            >
              {renderRow(activeRow)}
            </Flex>
          )}
          {leaderBoardValues
            .filter((s) => s.walletId !== walletAddress)
            .map((row, idx) => (
              <Flex key={idx} className={classNames(styles.leaderboardRow)}>
                {renderRow(row)}
              </Flex>
            ))}
        </Flex>
      </Flex>
    </div>
  );
}
