import { useContext } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import { APP_ROOT_ELEMENT_ID } from '../../../constants';
import { Flex } from '../../ui/Flex';
import { Title } from '../../ui/Typography';
import { ModalProviderContext } from '../../core/ModalProvider';
import { ModalPropsType } from './Modal.types';
import styles from './Modal.module.scss';

ReactModal.setAppElement(`#${APP_ROOT_ELEMENT_ID}`);

export function Modal({
  id,
  theme,
  shouldCloseOnOverlayClick = false,
  overlayClassName,
  className,
  onClose,
  children,
  title,
}: ModalPropsType) {
  const { getIsOpen, onRemove } = useContext(ModalProviderContext);
  const isSideBar = theme === 'sidebar';

  return (
    <ReactModal
      isOpen={getIsOpen(id)}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onClose}
      closeTimeoutMS={isSideBar ? 300 : 0}
      onAfterClose={() => onRemove(id)}
      className={classNames(
        styles.modal,
        {
          [styles.wide]: theme === 'wide',
          [styles.safari]: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
        },
        className,
      )}
      overlayClassName={classNames(styles.modalOverlay, overlayClassName)}
      bodyOpenClassName={styles.bodyModalOpen}
    >
      <section>
        <Flex as='header' alignItems='baseline' justify='space-between'>
          <div>
            {isSideBar && title && (
              <Title as='h2' offset={{ right: 12, bottom: 24 }}>
                {title}
              </Title>
            )}
          </div>
        </Flex>
        <div className={styles.confirmContent}>
          {!isSideBar && title && (
            <Title align='center' as='h3' offset={{ top: 16, bottom: 24 }}>
              {title}
            </Title>
          )}
          <>{children}</>
        </div>
      </section>
    </ReactModal>
  );
}
