import React, { forwardRef, Ref } from 'react';
import classNames from 'classnames';

import { ButtonPropsTypes } from './Button.types';
import styles from './Button.module.scss';

const Button = forwardRef(
  <Link extends string | undefined>(
    {
      icon,
      onClick,
      loading = false,
      children,
      className,
      disabled,
      skin = 'primary',
      fluid = false,
      style,
      size = 'medium',
      link,
      type,
      target,
    }: ButtonPropsTypes<Link>,
    ref: null | Link extends string ? Ref<HTMLAnchorElement | null> : Ref<HTMLButtonElement | null>,
  ) => {
    const isShellButton = skin === 'shell';
    const isLinkButton = skin === 'link';
    const commonProps = {
      disabled: disabled || loading,
      className: classNames(
        styles.button,
        {
          [styles.loading]: loading,
          [styles.disabled]: disabled,
          [styles.small]: size === 'small' && !isShellButton && !isLinkButton,
          [styles.medium]: size === 'medium' && !isShellButton && !isLinkButton,
          [styles.large]: size === 'large' && !isShellButton && !isLinkButton,
          [styles.primary]: skin === 'primary' && !loading,
          [styles.outlined]: skin === 'outlined' && !loading,
          [styles.secondary]: skin === 'secondary' && !loading,
          [styles.shell]: isShellButton,
          [styles.link]: isLinkButton,
          [styles.fluid]: fluid,
        },
        className,
      ),
      style,
    };

    if (link !== undefined) {
      return (
        <a
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...commonProps}
          role='button'
          ref={ref as Ref<HTMLAnchorElement>}
          href={!disabled ? link : undefined}
          target={target}
          onClick={
            !disabled
              ? (onClick as (
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ) => void | undefined)
              : undefined
          }
        >
          <p>{children}</p>
          {icon && <p className={styles.icon}>{icon}</p>}
        </a>
      );
    }

    return (
      <button
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...commonProps}
        ref={ref as Ref<HTMLButtonElement>}
        /* eslint-disable-next-line react/button-has-type */
        type={type || 'button'}
        onClick={
          onClick as (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined
        }
      >
        <div>{children}</div>
        {icon && (
          <div
            className={classNames(styles.icon, {
              [styles.withoutChildren]: !children,
            })}
          >
            {icon}
          </div>
        )}
      </button>
    );
  },
);

export default Button;
