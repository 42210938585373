import styles from './steps-block.module.scss';
import { Flex, Grid, Text } from '../../../components';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/icons/arrow-icon.svg';
import { useIsMobileDevice } from '../../../hooks/device';

export function StepsBlock() {
  const isMobile = useIsMobileDevice();
  const steps = [
    {
      title: '01',
      description: 'Connect your wallet',
    },
    {
      title: '02',
      description: 'Play games and earn',
    },
    {
      title: '03',
      description: 'Trade on marketplace',
    },
  ];
  return (
    <div className={styles.container}>
      <Flex className='container mx-auto' justify='center' direction={isMobile ? 'column' : 'row'}>
        {steps.map((step, idx) => (
          <Grid
            key={step.title}
            columns={isMobile ? '1fr' : idx === steps.length - 1 ? '174px' : '174px 60px'}
            colGap={isMobile ? 0 : 89}
            rowGap={isMobile ? 36 : 0}
            align='center'
            offset={{
              left: idx === 0 ? 0 : isMobile ? 0 : 89,
              bottom: idx === steps.length - 1 ? 0 : isMobile ? 36 : 0,
            }}
          >
            <Flex direction='column' alignItems='center' justify='center'>
              <Text font='Yapari Variable Trial' size='60-64' color='contrast'>
                {step.title}
              </Text>
              <Text size='16-24' color='contrast'>
                {step.description}
              </Text>
            </Flex>
            <Flex justify={isMobile ? 'center' : undefined}>
              {idx === steps.length - 1 ? null : <ArrowIcon className={styles.icon} />}
            </Flex>
          </Grid>
        ))}
      </Flex>
    </div>
  );
}
