import { StepType } from '@reactour/tour';

export function getSteps(isMobile: boolean): StepType[] {
  return [
    {
      selector: '.first-step',
      content: 'This is my first Step',
      position: 'center',
    },
    {
      selector: '.second-step',
      content: 'This is my second Step',
      position: isMobile ? 'center' : 'bottom',
    },
    {
      selector: '.third-step',
      content: 'This is my third Step',
      position: 'bottom',
    },
    {
      selector: '.fourth-step',
      content: 'Fourth is my third Step',
      position: 'bottom',
    },
    {
      selector: isMobile ? '.tournaments' : '.fifth-step',
      content: 'Fifth is my third Step',
      position: 'bottom',
    },
  ];
}
