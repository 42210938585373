import { WalletAccount } from '@cere/embed-wallet';

import { useCallback, useEffect, useState } from 'react';
import { useWallet } from './use-wallet';

export const useWalletAddress = (type: 'etherium' | 'cere' = 'etherium') => {
  const wallet = useWallet();
  const [address, setAddress] = useState<string>();

  const update = useCallback(
    ([ethAccount, cereAccount]: WalletAccount[]) => {
      setAddress(type === 'etherium' ? ethAccount?.address : cereAccount?.address);
    },
    [wallet, type],
  );

  useEffect(() => {
    if (wallet.status === 'connected') {
      wallet.getAccounts().then(update);
    }

    const unsubscribe = wallet.subscribe('accounts-update', update);

    return () => unsubscribe();
  }, [wallet, update]);

  return address;
};
