import { forwardRef } from 'react';
import classNames from 'classnames';

import { buildOffsetStyles } from '../../../utils';
import { GridPropsType } from './Grid.types';
import styles from './Grid.module.scss';

const Grid = forwardRef<HTMLElement, GridPropsType>(
  (
    {
      as: Component = 'div',
      inline = false,
      align,
      justify,
      alignContent,
      justifyContent,
      autoColumns,
      autoRows,
      autoFlow,
      template,
      columns,
      rows,
      areas,
      colGap,
      rowGap,
      className,
      children,
      offset,
    },
    ref,
  ) => (
    <Component
      ref={ref}
      className={classNames(
        styles.relative,
        {
          [styles.grid]: !inline,
          [styles.inlineGrid]: inline,
        },
        className,
      )}
      style={{
        ...buildOffsetStyles(offset),
        gridTemplate: template,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        gridTemplateAreas: areas,
        alignItems: align,
        justifyItems: justify,
        alignContent,
        justifyContent,
        gridAutoColumns: autoColumns,
        gridAutoRows: autoRows,
        gridAutoFlow: autoFlow,
        columnGap: colGap,
        gridColumnGap: colGap,
        gridRowGap: rowGap,
        rowGap: rowGap, // eslint-disable-line object-shorthand
      }}
    >
      {children}
    </Component>
  ),
);

export default Grid;
