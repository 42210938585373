import { SliderPropsType } from './slider.types';
import { SetStateAction, useCallback, useLayoutEffect, useEffect, useRef, useState } from 'react';
import styles from './slider.module.scss';
import { ReactComponent as DotIcon } from '../../../assets/svg/icons/dot.svg';
import { Slide } from './components/slide/slide';
import classNames from 'classnames';
import { useIsMobileDevice } from '../../../hooks/device';

const slidesContainerStyles = {
  display: 'flex',
  height: '100%',
  transition: 'transform ease-out 0.3s',
};

export function Slider({ slides }: SliderPropsType) {
  const isMobile = useIsMobileDevice();
  const [parentWidth, setParentWidth] = useState(0);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useLayoutEffect(() => {
    const containerWidth = document.getElementById('slider-container')?.clientWidth;

    setParentWidth(containerWidth as number);
  });

  const getSlidesContainerStylesWithWidth = () => {
    return {
      ...slidesContainerStyles,
      width: isMobile ? 'auto' : (parentWidth || 1260) * slides.length,
      transform: `translateX(${-(currentIndex * (parentWidth || 1260))}px)`,
      marginLeft: '1px',
    };
  };

  const goToNext = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides]);
  const goToSlide = (slideIndex: SetStateAction<number>) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (!timerRef) {
      return;
    }

    timerRef.current = setTimeout(() => {
      goToNext();
    }, 10000);

    return () => (timerRef.current ? clearTimeout(timerRef?.current) : undefined);
  }, [goToNext]);

  return (
    <div className={styles.sliderStyles} id='slider-container'>
      <div className={styles.slidesContainerOverflowStyles}>
        <div style={getSlidesContainerStylesWithWidth()}>
          {slides.map((slide) => (
            <div key={slide.id} className={styles.slideStyles}>
              <Slide {...slide} />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            className={classNames(styles.dotStyle, {
              [styles.dotActive]: slideIndex === currentIndex,
            })}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            <DotIcon />
          </div>
        ))}
      </div>
    </div>
  );
}
