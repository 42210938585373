import React from 'react';
import Game1 from '../../../assets/png/game-1.png';
import Game2 from '../../../assets/png/game-2.png';
import { ReactComponent as GameSubstrate } from '../../../assets/svg/icons/game-substrate.svg';
import styles from './play-games.module.scss';
import { Button, Flex, Text } from '../../../components';
import { useIsMobileDevice } from '../../../hooks/device';
import classNames from 'classnames';
import * as process from 'process';

const gamesList = [
  {
    id: 1,
    image: Game1,
    description:
      'The goal of the game is to reach the end of the level or course as quickly as possible, while avoiding obstacles and enemies and collecting power-ups or other rewards along the way.',
    url: process.env.REACT_APP_METAVERSE_DASH_URL,
    title: 'Metaverse Dash Run',
  },
  {
    id: 2,
    image: Game2,
    description:
      'Match 3 or more of the same candies to score points! Feeling stuck? Exploding candy bombs will come to the rescue, helping you advance to the next stage.',
    url: process.env.REACT_APP_CANDY_JAM_URL,
    title: 'Candy Jam',
  },
];

export function PlayGames() {
  const isMobile = useIsMobileDevice();
  return (
    <div className={styles.bg}>
      <Flex
        direction='column'
        className={classNames('container mx-auto lg:max-w-[1158px]', styles.container)}
        offset={{ bottom: isMobile ? 37 : 140 }}
      >
        <Text
          className='third-step'
          size={isMobile ? '24-28' : '47-58'}
          font='Yapari Variable Trial'
          color='gray'
        >
          FEATURED GAMES
        </Text>
        <Flex direction='column' rowGap={isMobile ? 48 : 117} offset={{ top: isMobile ? 36 : 96 }}>
          {gamesList.map((game) => (
            <Flex
              key={game.id}
              colGap={60}
              className={styles.game}
              direction={isMobile ? 'column' : 'row'}
            >
              <div className={classNames(styles.picture, 'forScroll')}>
                <GameSubstrate className={styles.gameSubstrate} />
                <img className={styles.gameImage} src={game.image} alt={game.title} />
              </div>
              <Flex direction='column' justify='space-between'>
                <Text
                  className={styles.gameTitle}
                  font='Yapari Variable Trial'
                  size='24-28'
                  color='gray'
                  offset={{ top: isMobile ? 24 : 0 }}
                >
                  {game.title}
                </Text>
                <Text
                  size='16-24'
                  color='gray'
                  offset={{ top: isMobile ? 20 : 16, bottom: isMobile ? 22 : 48 }}
                  className={styles.description}
                >
                  {game.description}
                </Text>
                <Button className={styles.playGame} link={game.url} target='_blank'>
                  Play game
                </Button>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </div>
  );
}
