import classNames from 'classnames';

import Bg from '../../../assets/png/bg.png';
import { ReactComponent as MonetizationIcon } from '../../../assets/svg/icons/monetization.svg';
import { ReactComponent as OptimizeCacIcon } from '../../../assets/svg/icons/optimize-cac-icon.svg';
import { ReactComponent as RewardIcon } from '../../../assets/svg/icons/reward-icon.svg';
import { Button, Flex, Grid, Text } from '../../../components';
import { useIsMobileDevice } from '../../../hooks/device';
import styles from './own-portal.module.scss';
import { CONTACT_US_URL } from '../../../constants';

export function OwnPortal() {
  const isMobile = useIsMobileDevice();
  return (
    <div className='relative z-10'>
      {!isMobile && (
        <>
          <img className={classNames(styles.bg, styles.bg1)} src={Bg} alt='background' />
          <img className={classNames(styles.bg, styles.bg2)} src={Bg} alt='background' />
        </>
      )}
      <Flex
        className='container mx-auto lg:max-w-[1158px]'
        offset={{ top: isMobile ? 64 : 128 }}
        direction='column'
      >
        <Text
          className={styles.title}
          align='center'
          size={isMobile ? '24-28' : '47-58'}
          font='Yapari Variable Trial'
          color='gray'
        >
          Create your {isMobile && <br />} own
        </Text>
        <Text
          className={styles.title}
          align='center'
          size={isMobile ? '24-28' : '47-58'}
          font='Yapari Variable Trial'
          color={isMobile ? 'accent' : 'gray'}
          offset={{ bottom: 48 }}
        >
          gaming portal
        </Text>
        <Grid columns={isMobile ? '1fr' : 'repeat(3, 230px)'} justifyContent='space-evenly'>
          <Flex
            direction='column'
            justify='center'
            alignItems='center'
            offset={{ bottom: isMobile ? 48 : 0 }}
          >
            <Flex className={styles.iconBlock} alignItems='center' justify='center'>
              <OptimizeCacIcon />
            </Flex>
            <Text
              align='center'
              size='24-28'
              color='gray'
              weight='semibold'
              offset={{ top: isMobile ? 24 : 36, bottom: 14 }}
            >
              Optimize CAC
            </Text>
            <Text align='center' size='16-20' color='gray' className={styles.description}>
              Acquire users quickly and scale faster than the competition.
            </Text>
          </Flex>
          <Flex
            direction='column'
            justify='center'
            alignItems='center'
            offset={{ bottom: isMobile ? 48 : 0 }}
          >
            <Flex className={styles.iconBlock} alignItems='center' justify='center'>
              <MonetizationIcon />
            </Flex>
            <Text
              align='center'
              size='24-28'
              color='gray'
              weight='semibold'
              offset={{ top: isMobile ? 24 : 36, bottom: 14 }}
            >
              Monetization
            </Text>
            <Text align='center' size='16-20' color='gray' className={styles.description}>
              Cut out the middleman and build your own custom marketplace.
            </Text>
          </Flex>
          <Flex direction='column' justify='center' alignItems='center'>
            <Flex className={styles.iconBlock} alignItems='center' justify='center'>
              <RewardIcon />
            </Flex>
            <Text
              align='center'
              size='24-28'
              color='gray'
              weight='semibold'
              offset={{ top: isMobile ? 24 : 36, bottom: 14 }}
            >
              Interoperability
            </Text>
            <Text align='center' size='16-20' color='gray' className={styles.description}>
              Allow your players to take gaming achievements with them wherever they play.
            </Text>
          </Flex>
        </Grid>
        <Flex
          className={styles.buttonGroup}
          justify='center'
          direction={isMobile ? 'column' : 'row'}
          rowGap={isMobile ? 10 : 0}
          alignItems='center'
          colGap={10}
          offset={{ top: 64, bottom: isMobile ? 128 : 144.26 }}
        >
          <Button link={CONTACT_US_URL}>Contact Us</Button>
          <Button skin='outlined' link='https://cere.network/game-developer/'>
            Learn more
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}
