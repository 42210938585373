import classNames from 'classnames';
import React from 'react';

import { ReactComponent as FbIcon } from '../../../assets/svg/icons/fb-icon.svg';
import { ReactComponent as InIcon } from '../../../assets/svg/icons/in-icon.svg';
import { ReactComponent as TgIcon } from '../../../assets/svg/icons/tg-icon.svg';
import { ReactComponent as TwIcon } from '../../../assets/svg/icons/tw-icon.svg';
import { useIsDesktopDevice } from '../../../hooks/device';
import styles from './footer.module.scss';

export function Footer() {
  const isDesktop = useIsDesktopDevice();
  return (
    <div className={styles.footerBorderWrap}>
      <footer
        className={classNames(
          'pt-[40px] pb-[48px]',
          styles.footer,
          'container mx-auto lg:max-w-[1158px]',
        )}
      >
        <div className={classNames('container mx-auto flex justify-between', styles.row)}>
          <div className='flex flex-col'>
            <div className={styles.social}>
              <a href='https://www.facebook.com/Cerenetwork/'>
                <FbIcon />
              </a>
              <a href='https://www.linkedin.com/company/cere-network'>
                <InIcon />
              </a>
              <a href='https://twitter.com/cerenetwork'>
                <TwIcon />
              </a>
              <a href='https://t.me/cerenetwork'>
                <TgIcon />
              </a>
            </div>
            {isDesktop && (
              <p className='text-[#9A9A9A] mt-[70px]'>© 2023 Cere Network. All Rights Reserved</p>
            )}
          </div>
          <div className={classNames('grid grid-cols-[1fr_1fr] gap-[96px]', styles.col)}>
            <div className='flex flex-col'>
              <a
                href='https://cere.network/cookie-statement'
                className={classNames(styles.link, 'mb-6', styles.cookieStatement)}
              >
                Cookie Statement
              </a>
              {isDesktop ? (
                <a
                  href='https://cere.network/privacy-statement'
                  className={classNames(styles.link)}
                >
                  Privacy Policy
                </a>
              ) : (
                <a href='#' className={classNames(styles.link, styles.getInTouch, 'font-semibold')}>
                  Get in Touch
                </a>
              )}
            </div>
            <div className='flex flex-col'>
              {isDesktop ? (
                <a
                  href='#'
                  className={classNames(styles.link, styles.getInTouch, 'mb-6 font-semibold')}
                >
                  Get in Touch
                </a>
              ) : (
                <a
                  href='https://cere.network/privacy-statement'
                  className={classNames(styles.link)}
                >
                  Privacy Policy
                </a>
              )}
              <a
                className={classNames(styles.link, styles.email, 'mb-9 underline')}
                href='mailto:team@cere.network'
              >
                team@cere.network
              </a>
            </div>
          </div>
          {!isDesktop && (
            <p className='text-[#9A9A9A] mt-[32px]'>
              © {new Date().getFullYear()} Cere Network. All Rights Reserved
            </p>
          )}
        </div>
      </footer>
    </div>
  );
}
