import { useMemo } from 'react';
import classNames from 'classnames';

import { buildOffsetStyles } from '../../../../../utils';
import { TextPropsType } from './Text.types';
import styles from './Text.module.scss';

export default function Text({
  align: textAlign,
  as: Component = 'p',
  children,
  className,
  color = 'default',
  dateTime,
  font = 'Lexend',
  offset,
  onClick,
  opacity,
  size = '14-20',
  weight = 'regular',
  fontStyle = 'normal',
}: TextPropsType) {
  const isCerePlay = font === 'Yapari Variable Trial';
  const componentStyles = useMemo(
    () => ({
      ...buildOffsetStyles(offset),
      textAlign,
      opacity,
    }),
    [offset, textAlign, opacity],
  );

  return (
    <Component
      dateTime={dateTime}
      onClick={onClick}
      style={componentStyles}
      className={classNames(styles.text, styles[`text-${size}`], className, {
        [styles.cerePlay]: isCerePlay,

        [styles.light]: isCerePlay || weight === 'light',
        [styles.regular]: !isCerePlay && weight === 'regular',
        [styles.medium]: !isCerePlay && weight === 'medium',
        [styles.semibold]: !isCerePlay && weight === 'semibold',
        [styles.bold]: !isCerePlay && weight === 'bold',
        [styles.extraBold]: !isCerePlay && weight === 'extraBold',
        [styles.black]: !isCerePlay && weight === 'black',

        [styles.normal]: fontStyle === 'normal',
        [styles.italic]: fontStyle === 'italic',
        [styles.oblique]: fontStyle === 'oblique',

        [styles.contrast]: color === 'contrast',
        [styles.contrast]: color === 'contrast',
        [styles.default]: color === 'default',
        [styles.gray030]: color === 'gray030',
        [styles.gray040]: color === 'gray040',
        [styles.accent]: color === 'accent',
        [styles.lightColor]: color === 'light',
        [styles.gray]: color === 'gray',
      })}
    >
      {children}
    </Component>
  );
}
