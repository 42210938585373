import React, { useState } from 'react';
import { useTour } from '@reactour/tour';

import { Dialog } from '@headlessui/react';
import { Button, Logo, Text } from '../../../components';
import { ReactComponent as MenuIcon } from '../../../assets/svg/icons/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icons/close-icon.svg';

import styles from './header.module.scss';
import classNames from 'classnames';
import { Truncate, useWallet, useWalletAddress, useWalletStatus } from '../../../hooks';
import { useIsMobileDevice } from '../../../hooks/device';

const navigation = [
  { name: 'Game Portal', href: '/', active: true },
  { name: 'Marketplace', href: '/marketplace', active: false, disabled: true },
];

export function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const wallet = useWallet();
  const walletAddress = useWalletAddress();
  const walletStatus = useWalletStatus();
  const isWalletConnectDisabled = walletStatus !== 'ready';
  const isMobile = useIsMobileDevice();
  const { setIsOpen } = useTour();
  return (
    <header className={classNames(styles.header)}>
      <div className='container mx-auto lg:max-w-[1158px]'>
        <nav
          className={classNames(
            'flex items-center justify-between py-6 lg:py-8 md:py-8',
            styles.nav,
          )}
          aria-label='Global'
        >
          <div className='flex lg:flex-1 md:flex-1'>
            <Logo className='h-8 w-auto first-step' />
          </div>
          <div className='flex lg:hidden md:hidden'>
            <button
              type='button'
              className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className='sr-only'>Open main menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          {!isMobile && (
            <div className='lg:flex lg:gap-x-6 mr-[40px] flex-[0.7_1_0%]'>
              {navigation.map((item) =>
                item.disabled ? (
                  <div key={item.name} className={styles.navLink}>
                    {item.name}
                  </div>
                ) : (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(styles.navLink, 'relative leading-6 text-gray-900', {
                      [styles.active]: item.active,
                    })}
                  >
                    {item.name}
                  </a>
                ),
              )}
            </div>
          )}
          {!isMobile && (
            <div className='lg:flex md:flex lg:flex-1 md:flex-1 lg:justify-end md:justify-end max-w-[280px]'>
              <div className='grid grid-cols-[auto_auto] gap-x-[13px]'>
                <Button skin='outlined' onClick={() => setIsOpen(true)}>
                  Developer Guide
                </Button>
                <Button
                  className='second-step'
                  disabled={isWalletConnectDisabled}
                  onClick={() => wallet.connect()}
                >
                  {walletAddress ? (
                    <Truncate variant='hex' maxLength={10} text={walletAddress} />
                  ) : (
                    'Connect your wallet'
                  )}
                </Button>
              </div>
            </div>
          )}
        </nav>
        <Dialog
          as='div'
          className='lg:hidden md:hidden'
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className='fixed inset-0 z-50' />
          <Dialog.Panel
            className={classNames(
              'fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10',
              styles.mobileMenu,
            )}
          >
            <div className={classNames('flex items-center justify-between', styles.mobileHeader)}>
              <div className={classNames('flex lg:flex-1')}>
                <Logo className='h-8 w-auto first-step' whiteFill />
              </div>
              <button
                type='button'
                className={classNames(styles.close)}
                onClick={() => setMobileMenuOpen(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={styles.mobileNav}>
              {navigation.map((item) =>
                item.disabled ? (
                  <Text
                    key={item.name}
                    opacity={0.37}
                    font='Yapari Variable Trial'
                    size='24-28'
                    color='gray'
                    align='center'
                  >
                    {item.name}
                  </Text>
                ) : (
                  <a key={item.name} href={item.href}>
                    <Text
                      className={styles.mobileNavItem}
                      font='Yapari Variable Trial'
                      size='24-28'
                      color='gray'
                      align='center'
                      offset={{ bottom: 36 }}
                    >
                      {item.name}
                    </Text>
                  </a>
                ),
              )}
            </div>
            <div className={styles.menuButtons}>
              <Button
                className={styles.connectWallet}
                disabled={isWalletConnectDisabled}
                onClick={() => wallet.connect()}
              >
                {walletAddress ? (
                  <Truncate variant='hex' maxLength={10} text={walletAddress} />
                ) : (
                  'Connect your wallet'
                )}
              </Button>
              <Button
                className={styles.developerGuide}
                onClick={() => {
                  setMobileMenuOpen(false);
                  setIsOpen(true);
                }}
              >
                Developer Guide
              </Button>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </header>
  );
}
