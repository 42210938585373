import styles from './nft-for-sale.module.scss';
import { ReactComponent as UsdcIcon } from '../../../assets/svg/icons/usdc-icon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/svg/icons/share-icon.svg';
import { Button, Flex, Grid, Text } from '../../../components';
import { NftForSaleTypes } from './nft-for-sale.types';
import { useMemo } from 'react';

export function NftForSale({ shouldShare = true, nftImage, nftInfo }: NftForSaleTypes) {
  const showNftInfo = useMemo(() => {
    if (shouldShare && !nftInfo) {
      return true;
    }
    return !!nftInfo;
  }, []);
  return (
    <Grid className={styles.container} rows={showNftInfo ? '286px 144px' : '1fr'}>
      <div className='relative'>
        <img className={styles.nftImage} src={nftImage} alt='' />
        {shouldShare && (
          <div className={styles.share}>
            <ShareIcon />
          </div>
        )}
      </div>
      {showNftInfo && (
        <div className={styles.info}>
          <Text size='13-16' color='gray' opacity={0.66}>
            {nftInfo?.title}
          </Text>
          <Text size='16-20' color='gray' weight='semibold' offset={{ top: 2 }}>
            {nftInfo?.description}
          </Text>
          <Grid columns='auto 178px' align='center' offset={{ top: 14 }}>
            <div>
              <Text size='13-16' color='gray' opacity={0.66}>
                {nftInfo?.stock}
              </Text>
              <Flex colGap={8} offset={{ top: 7.3 }}>
                <UsdcIcon className={styles.usdc} />
                <Text color='gray' weight='semibold'>
                  {nftInfo?.price} USDC
                </Text>
              </Flex>
            </div>
            <Button className={styles.connectWallet}>Connect wallet</Button>
          </Grid>
        </div>
      )}
    </Grid>
  );
}
