import { Button, Flex, Grid, Leaderboard, Text } from '../../../components';
import { ReactComponent as DiscordIcon } from '../../../assets/svg/icons/discord-icon.svg';
import MysteryBox from '../../../assets/png/mystery-box.png';
import styles from './tournaments.module.scss';
import classNames from 'classnames';
import { useIsMobileDevice } from '../../../hooks/device';
import {
  CANDY_JAM_GAME_URL,
  METAVERSE_DASH_GAME_URL,
  METAVERSE_DASH_RUN_GAME_CODE,
} from '../../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as process from 'process';
import { Tournament } from '../../../types';

export function Tournaments() {
  const [activeTournament, setActiveTournament] = useState<Tournament | undefined>(undefined);

  const isMobile = useIsMobileDevice();

  const getActiveTournament = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/tournament/active/${METAVERSE_DASH_RUN_GAME_CODE}`,
    );
    if (response.ok) {
      const [tournament]: Tournament[] = await response.json();
      setActiveTournament(tournament);
    }
  }, []);

  useEffect(() => {
    void getActiveTournament();
  }, []);

  const tournamentSubtitle = useMemo(() => {
    return (
      <>
        {activeTournament ? (
          <Text
            className={styles.tournamentSubtitle}
            align='left'
            font='Yapari Variable Trial'
            size='36-45'
            color='gray'
            offset={{ top: 66 }}
          >
            {activeTournament.subtitle}
          </Text>
        ) : (
          <>
            <Text
              align='left'
              font='Yapari Variable Trial'
              size='36-45'
              color='gray'
              offset={{ top: 66 }}
            >
              TOP 20
            </Text>
            <Text align='left' font='Yapari Variable Trial' size='36-45' color='gray'>
              PLAYERS WINS
            </Text>
            <Text align='left' font='Yapari Variable Trial' size='36-45' color='accent'>
              UNIQUE NFT
            </Text>
          </>
        )}
      </>
    );
  }, [activeTournament?.subtitle]);

  const tournamentDates = useMemo(() => {
    if (!activeTournament) {
      return '15 May - 21 May 2023';
    }
    const startDate = new Date(activeTournament.startDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    });
    const endDate = new Date(activeTournament.endDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    });

    const year = new Date(activeTournament.endDate).getFullYear();

    return `${startDate} - ${endDate} ${year}`;
  }, [activeTournament?.endDate, activeTournament?.startDate]);

  console.log('ACTIVE', activeTournament);
  return (
    <div className={classNames('container mx-auto lg:max-w-[1158px] z-10', styles.tournament)}>
      <Text
        font='Yapari Variable Trial'
        size={isMobile ? '24-28' : '47-58'}
        color='gray'
        offset={{ bottom: isMobile ? 36 : 48, top: isMobile ? 64 : 128 }}
        className={classNames('fifth-step', styles.title)}
      >
        Ongoing tournaments
      </Text>
      {!isMobile && (
        <div className={styles.container}>
          <Grid columns='auto 410px' colGap={57}>
            <Flex direction='column' justify='space-between' rowGap={16}>
              {!isMobile && (
                <Grid columns='202px auto'>
                  <img src={MysteryBox} alt='MysteryBox' className={styles.mysteryBox} />
                  <div>{tournamentSubtitle}</div>
                </Grid>
              )}
              <Flex className={styles.mainTournament} direction='column'>
                <Text size='14-20' color='gray'>
                  Metaverse Dash Run
                </Text>
                <Flex colGap={35}>
                  <Flex direction='column'>
                    <Text size='36-45' color='gray' weight='semibold'>
                      {activeTournament ? activeTournament.title : 'Ranking championship'}
                    </Text>
                    <Text size='14-20' color='gray'>
                      {tournamentDates}
                    </Text>
                  </Flex>
                  <Button
                    className={styles.playGame}
                    onClick={() => window.open(METAVERSE_DASH_GAME_URL, '_blank')}
                  >
                    Play game
                  </Button>
                </Flex>
              </Flex>
            </Flex>
            <Leaderboard gameId='metaverse-dash-run' />
          </Grid>
        </div>
      )}
      {isMobile && (
        <>
          <div className={classNames(styles.container, styles.withPadding, 'tournaments')}>
            <Grid columns='1fr'>
              <img src={MysteryBox} alt='MysteryBox' className={styles.mysteryBox} />
              <div>{tournamentSubtitle}</div>
            </Grid>
            <Text size='14-20' color='gray'>
              Metaverse Dash Run
            </Text>
            <Flex colGap={35} justify='space-between' direction={isMobile ? 'column' : 'row'}>
              <Flex direction='column'>
                <Text
                  size='24-28'
                  color='gray'
                  weight='semibold'
                  offset={{ top: isMobile ? 12 : 0, bottom: isMobile ? 3 : 0 }}
                >
                  {activeTournament ? activeTournament.title : 'Ranking championship'}
                </Text>
                <Text size='14-20' color='gray' offset={{ bottom: isMobile ? 24 : 0 }}>
                  {tournamentDates}
                </Text>
              </Flex>
              <Button
                className={styles.playGame}
                onClick={() => window.open(METAVERSE_DASH_GAME_URL, '_blank')}
              >
                Play game
              </Button>
            </Flex>
          </div>
          <Leaderboard gameId='metaverse-dash-run' />
        </>
      )}
      <Grid
        columns={isMobile ? '1fr' : 'repeat(2, 1fr)'}
        offset={{ top: 20 }}
        colGap={21}
        rowGap={isMobile ? 24 : 0}
      >
        <div
          className={classNames(styles.container, styles.withPadding, styles.tournamentContainer)}
        >
          <Text size='14-20' color='gray' offset={{ bottom: 12 }}>
            Candy Jam
          </Text>
          <Flex colGap={35} justify='space-between' direction={isMobile ? 'column' : 'row'}>
            <Flex direction='column'>
              <Text
                size='24-28'
                color='gray'
                weight='semibold'
                offset={{ top: isMobile ? 12 : 0, bottom: isMobile ? 3 : 0 }}
              >
                Monthly contest
              </Text>
              <Text size='14-20' color='gray' offset={{ bottom: isMobile ? 24 : 0 }}>
                1 March - 8 March 2024
              </Text>
            </Flex>
            <Button
              className={styles.playGame}
              onClick={() => window.open(CANDY_JAM_GAME_URL, '_blank')}
            >
              Play game
            </Button>
          </Flex>
        </div>
        <div
          className={classNames(styles.container, styles.withPadding, styles.tournamentContainer)}
        >
          <Text size='14-20' color='gray' offset={{ bottom: 12 }}>
            Secret game
          </Text>
          <Flex colGap={35} justify='space-between' direction={isMobile ? 'column' : 'row'}>
            <Flex direction='column'>
              <Text
                size='24-28'
                color='gray'
                weight='semibold'
                offset={{ top: isMobile ? 12 : 0, bottom: isMobile ? 3 : 0 }}
              >
                Guild wars
              </Text>
              <Text size='14-20' color='gray' offset={{ bottom: isMobile ? 24 : 0 }}>
                1 April - 8 April 2024
              </Text>
            </Flex>
            <Button disabled className={styles.playGame}>
              Coming soon
            </Button>
          </Flex>
        </div>
      </Grid>
      <Flex alignItems='center' justify='center' offset={{ top: isMobile ? 48 : 64 }}>
        <Button
          className={styles.joinCommunity}
          skin='secondary'
          icon={<DiscordIcon />}
          link='https://discord.com/channels/876823550533726219/878201487631745034'
        >
          Join our community
        </Button>
      </Flex>
    </div>
  );
}
