import { SlidePropsType } from './slide.types';
import styles from './slide.module.scss';
import { Grid, Text } from '../../../';
import { useIsMobileDevice } from '../../../../../hooks/device';
import { useMemo } from 'react';

export function Slide({ title, titleSize, subtitle, description, buttons }: SlidePropsType) {
  const isMobile = useIsMobileDevice();

  const titleSizeParam = useMemo(() => {
    if (!isMobile) {
      return !titleSize ? '60-64' : '47-58';
    }
    return '36-45';
  }, []);

  return (
    <Grid columns='auto 317px' colGap={75} align='center'>
      <div>
        <Text
          className={styles.title}
          size={titleSizeParam}
          font='Yapari Variable Trial'
          weight='semibold'
          color={subtitle ? 'accent' : 'light'}
          as='span'
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            className={styles.title}
            size={isMobile ? '36-45' : '60-64'}
            color='light'
            weight='semibold'
            font='Yapari Variable Trial'
          >
            {subtitle}
          </Text>
        )}
        <Text
          className={styles.description}
          size={isMobile ? '18-24' : '20-28'}
          color='light'
          offset={{ top: isMobile ? 24 : 0 }}
        >
          {description}
        </Text>
        <Grid
          className={styles.buttonGroup}
          columns={isMobile ? '1fr' : '195px 209px'}
          rowGap={isMobile ? 14 : 0}
          colGap={14}
          offset={{ top: isMobile ? 36 : 63.4 }}
        >
          {buttons.map((button) => button)}
        </Grid>
      </div>
    </Grid>
  );
}
