import { LogoPropsType } from './logo.types';
import { ReactComponent as LogoIcon } from '../../../assets/svg/logo/logo.svg';
import { ReactComponent as LogoTitleIcon } from '../../../assets/svg/logo/logo-title.svg';

import { Grid } from '../Grid';
import styles from './logo.module.scss';
import classNames from 'classnames';
import { ROOT_ROUTE } from '../../../constants';

export function Logo({
  clickable = false,
  withTitle = true,
  width,
  height,
  className,
  linkTo,
  whiteFill = false,
}: LogoPropsType) {
  const logo = (
    <LogoIcon
      width={width || 33.3}
      height={height || 28.63}
      className={classNames({ [styles.whiteIcon]: whiteFill })}
    />
  );
  if (!clickable) {
    return (
      <Grid align='center' className={classNames(styles.link, className)}>
        {logo}
        {withTitle && (
          <span>
            <LogoTitleIcon />
          </span>
        )}
      </Grid>
    );
  }
  return (
    <a href={linkTo || ROOT_ROUTE} className={classNames(styles.link, className)}>
      {logo}
      {withTitle && (
        <span>
          <LogoTitleIcon />
        </span>
      )}
    </a>
  );
}
