import { Slider, Button, BookDemoModal } from '../../../components';
import { Truncate, useModal, useWallet, useWalletAddress, useWalletStatus } from '../../../hooks';
import React from 'react';
import { ReactComponent as DiscordIcon } from '../../../assets/svg/icons/discord-icon.svg';
import styles from './slider-block.module.scss';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import { METAVERSE_DASH_GAME_URL } from '../../../constants';

export function SliderBlock() {
  const [openModal] = useModal(BookDemoModal);
  const { setIsOpen } = useTour();
  const wallet = useWallet();
  const walletAddress = useWalletAddress();
  const walletStatus = useWalletStatus();
  const isWalletConnectDisabled = walletStatus !== 'ready';
  const slides = [
    {
      id: 1,
      title: 'CerePlay',
      subtitle: 'Gaming Portal',
      description: 'Instantly play games, compete in tournaments and win awesome rewards!',
      buttons: [
        <Button key='1' onClick={() => window.open(METAVERSE_DASH_GAME_URL, '_blank')}>
          Start Playing Now
        </Button>,
        <Button
          key='2'
          skin='outlined'
          className={styles.connectWallet}
          disabled={isWalletConnectDisabled}
          onClick={() => wallet.connect()}
        >
          {walletAddress ? (
            <Truncate variant='hex' maxLength={10} text={walletAddress} />
          ) : (
            'Connect your wallet'
          )}
        </Button>,
      ],
    },
    {
      id: 2,
      title: 'Discord Gaming Community',
      description:
        'Become part of our community! Discuss the latest games, collectables and prizes.',
      buttons: [
        <Button
          key='1'
          icon={<DiscordIcon />}
          className={styles.joinCommunity}
          onClick={() => {
            window.open(
              'https://discord.com/channels/876823550533726219/878201487631745034',
              '_blank',
            );
          }}
        >
          Join our community
        </Button>,
      ],
    },
    {
      id: 3,
      title: 'Are you a Game Studio or Developer?',
      titleSize: '47-58',
      description:
        "This page is a small example of what can be built using Cere's tools and services. Find out more about building your own decentralized game store.",
      buttons: [
        <Button key='1' onClick={openModal}>
          Book Demo
        </Button>,
        <Button
          className={styles.connectWallet}
          key='2'
          skin='outlined'
          onClick={() => setIsOpen(true)}
        >
          Developer Guide
        </Button>,
      ],
    },
  ];
  return (
    <div className={classNames('container mx-auto lg:max-w-[1158px]', styles.sliderBlock)}>
      <Slider slides={slides} />
    </div>
  );
}
