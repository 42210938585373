import { useCallback, useMemo, useState } from 'react';

import { ModalProviderContext } from './ModalProvider.context';
import { ModalProviderPropsType, ModalProviderStateType } from './ModalProvider.types';

export function ModalProvider({ children }: ModalProviderPropsType) {
  const [modals, setModals] = useState<ModalProviderStateType[]>([]);

  const handleOnOpen = useCallback(
    (id: any, Modal: any, onComplete: any, onCancel: any, data: any) => {
      setModals((currentModals) => [
        ...currentModals,
        {
          id,
          isOpen: true,
          Modal,
          onComplete,
          onCancel,
          data,
        },
      ]);
    },
    [],
  );

  const handleOnClose = useCallback((closeModalId: string) => {
    setModals((openModals) =>
      openModals.map((modal) => {
        if (modal.id === closeModalId) {
          return { ...modal, isOpen: false };
        }

        return modal;
      }),
    );
  }, []);

  const handleClearClosed = useCallback((removeModalId: string) => {
    setModals((openModals) => openModals.filter((modal) => modal.id !== removeModalId));
  }, []);

  const handleGetIsOpen = useCallback(
    (modalId: string) => modals.find((modal) => modal.id === modalId)?.isOpen || false,
    [modals],
  );

  const handleOnCloseAllModals = useCallback(() => {
    setModals([]);
  }, []);

  const contextValue = useMemo(
    () => ({
      onOpen: handleOnOpen,
      onClose: handleOnClose,
      onRemove: handleClearClosed,
      getIsOpen: handleGetIsOpen,
      onClearAll: handleOnCloseAllModals,
    }),
    [handleClearClosed, handleGetIsOpen, handleOnClose, handleOnCloseAllModals, handleOnOpen],
  );

  return (
    <ModalProviderContext.Provider value={contextValue}>
      {children}
      {modals.map(({ Modal, onComplete, onCancel, data, id }) => {
        const modalProps = {
          id,
          onComplete: (modalData: unknown) => {
            onComplete?.(modalData);
            handleOnClose(id);
          },
          onCancel: () => {
            onCancel?.();
            handleOnClose(id);
          },
          ...(data || {}),
        };

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Modal key={id} {...modalProps} />;
      })}
    </ModalProviderContext.Provider>
  );
}
